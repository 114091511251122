import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
<path d="M7576 9621 c-4 -5 45 -9 112 -9 100 1 108 2 57 8 -86 11 -163 11
-169 1z"/>
<path d="M7473 9613 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M7368 9603 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M7853 9603 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M7240 9584 l-35 -14 34 5 c34 5 48 12 40 19 -2 2 -20 -3 -39 -10z"/>
<path d="M7930 9590 c19 -13 30 -13 30 0 0 6 -10 10 -22 10 -19 0 -20 -2 -8
-10z"/>
<path d="M7185 9570 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M7338 9573 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7999 9565 c18 -8 43 -15 55 -14 18 0 16 3 -9 14 -16 8 -41 14 -55
14 -23 0 -22 -1 9 -14z"/>
<path d="M7920 9566 c0 -8 88 -34 94 -28 4 3 -11 11 -31 19 -38 13 -63 16 -63
9z"/>
<path d="M7075 9545 c-16 -7 -23 -14 -15 -14 25 -1 60 11 60 20 0 12 -9 11
-45 -6z"/>
<path d="M7130 9556 c0 -2 6 -9 14 -15 10 -9 15 -8 20 4 4 10 -1 15 -14 15
-11 0 -20 -2 -20 -4z"/>
<path d="M7210 9545 c0 -8 7 -15 16 -15 8 0 13 4 9 9 -3 5 7 12 22 14 23 3 21
4 -9 6 -28 1 -38 -3 -38 -14z"/>
<path d="M8080 9550 c0 -9 69 -35 75 -28 2 1 -14 11 -36 20 -21 9 -39 13 -39
8z"/>
<path d="M7264 9529 c14 -9 15 -13 6 -19 -19 -11 14 -43 38 -37 14 4 13 6 -6
12 -12 4 -22 12 -21 19 3 26 -2 36 -18 36 -16 -1 -16 -2 1 -11z"/>
<path d="M7070 9520 l-35 -7 43 -2 c23 0 42 4 42 9 0 6 -3 9 -7 9 -5 -1 -24
-5 -43 -9z"/>
<path d="M7178 9523 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8038 9523 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M7140 9511 c8 -5 33 -12 55 -15 27 -4 34 -4 20 3 -31 13 -93 24 -75
12z"/>
<path d="M7218 9513 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7343 9513 c9 -2 15 -9 11 -14 -3 -5 -10 -6 -16 -3 -7 4 -8 2 -4 -4
10 -17 19 -15 34 8 11 18 10 20 -15 19 -18 -1 -21 -3 -10 -6z"/>
<path d="M8160 9501 c0 -6 7 -11 15 -11 9 0 38 -12 66 -26 28 -14 48 -20 44
-15 -15 25 -125 70 -125 52z"/>
<path d="M8180 9460 c0 -5 4 -10 9 -10 5 0 18 -3 28 -7 16 -6 16 -5 4 10 -15
19 -41 23 -41 7z"/>
<path d="M7421 9446 c-9 -10 -8 -15 4 -20 10 -4 15 1 15 14 0 24 -4 25 -19 6z"/>
<path d="M7510 9430 c-11 -8 -7 -9 15 -4 37 8 45 14 19 14 -10 0 -26 -5 -34
-10z"/>
<path d="M7577 9423 c-9 -2 -15 -9 -12 -14 9 -14 22 -11 29 6 3 8 4 14 3 14
-1 -1 -10 -3 -20 -6z"/>
<path d="M7490 9405 c0 -7 30 -13 34 -7 3 4 -4 9 -15 9 -10 1 -19 0 -19 -2z"/>
<path d="M8324 9395 c-9 -23 10 -38 35 -30 24 7 20 19 -10 35 -15 9 -21 7 -25
-5z m36 -15 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4
15 -10z"/>
<path d="M7540 9380 c19 -13 30 -13 30 0 0 6 -10 10 -22 10 -19 0 -20 -2 -8
-10z"/>
<path d="M7610 9350 c-11 -8 -7 -9 15 -4 37 8 45 14 19 14 -10 0 -26 -5 -34
-10z"/>
<path d="M8400 9353 c0 -13 48 -68 54 -62 3 2 -8 20 -25 38 -16 19 -29 29 -29
24z"/>
<path d="M7630 9329 c0 -8 32 -29 44 -29 15 0 3 16 -19 27 -14 6 -25 7 -25 2z"/>
<path d="M8400 9301 c0 -10 19 -25 25 -20 1 2 -3 10 -11 17 -8 7 -14 8 -14 3z"/>
<path d="M7700 9275 c0 -1 15 -14 32 -31 18 -16 24 -19 14 -6 -11 12 -16 25
-12 29 4 4 12 1 17 -7 9 -13 10 -13 6 0 -2 8 -15 15 -30 15 -15 1 -27 0 -27 0z"/>
<path d="M6695 9254 c-390 -58 -795 -225 -1235 -508 -52 -33 -102 -66 -110
-73 -8 -6 -62 -46 -120 -89 -58 -42 -107 -80 -110 -83 -3 -3 -32 -28 -65 -55
-33 -28 -90 -78 -127 -113 -36 -35 -70 -63 -75 -64 -4 0 -36 -2 -71 -4 -35 -2
-65 -4 -66 -6 -2 -2 -79 -121 -171 -264 -92 -143 -198 -307 -236 -365 -37 -58
-118 -181 -178 -275 -60 -93 -178 -276 -261 -405 -84 -129 -226 -350 -316
-490 l-165 -255 283 -3 c217 -2 285 1 294 10 6 7 58 87 114 178 57 91 108 168
114 172 6 4 173 7 371 5 l360 -2 11 -95 c7 -52 15 -134 19 -182 l7 -88 916 1
917 0 90 115 c191 245 365 511 452 688 32 66 34 76 28 136 -11 118 -66 203
-159 246 -84 38 -164 46 -529 51 -334 5 -349 6 -379 27 -40 27 -51 66 -37 136
18 88 46 154 81 188 66 63 58 62 592 62 l484 0 11 33 c5 17 26 93 45 167 20
74 40 151 46 170 6 21 6 38 1 43 -6 6 -240 7 -588 4 -489 -5 -590 -8 -653 -22
-265 -59 -416 -210 -514 -514 -82 -254 -98 -452 -43 -557 38 -74 101 -123 202
-154 27 -9 160 -15 420 -19 417 -8 437 -11 451 -68 9 -34 -12 -145 -37 -203
-26 -58 -45 -79 -94 -101 -37 -17 -78 -19 -607 -19 -428 0 -568 3 -568 12 0
23 -170 1554 -177 1598 l-8 45 -197 0 c-122 1 -197 4 -197 10 4 27 236 175
409 261 367 183 732 284 1140 314 397 30 761 -51 981 -218 84 -64 140 -132
191 -229 22 -43 42 -80 45 -83 11 -11 -9 234 -27 320 -52 254 -173 433 -359
529 -132 68 -197 83 -396 86 -93 2 -183 1 -200 -1z m-1821 -1970 c13 -175 21
-322 19 -326 -7 -13 -453 -10 -453 3 0 11 34 68 260 439 148 243 144 237 148
219 2 -9 13 -159 26 -335z"/>
<path d="M8480 9250 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8920 9221 c42 -83 80 -194 101 -293 18 -89 20 -126 16 -278 -4 -153
-8 -189 -35 -292 -139 -535 -519 -1056 -1248 -1713 -448 -404 -1131 -895
-1954 -1405 -91 -56 -154 -99 -140 -95 37 11 350 137 460 185 52 23 120 52
150 65 132 56 501 234 703 341 244 129 528 298 742 442 840 568 1368 1239
1496 1902 27 145 30 379 6 513 -40 219 -135 438 -271 624 -39 53 -53 55 -26 4z"/>
<path d="M8501 9200 c-8 -4 -11 -12 -7 -18 3 -6 2 -13 -4 -17 -5 -3 -10 -14
-9 -23 0 -14 2 -13 9 3 5 11 9 16 10 10 1 -5 5 1 10 15 l7 25 11 -27 c12 -30
22 -36 22 -15 0 23 -35 56 -49 47z"/>
<path d="M7816 9184 c-4 -10 -1 -24 7 -31 11 -11 13 -9 12 11 0 31 -11 42 -19
20z"/>
<path d="M8510 9130 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8560 9120 c0 -8 6 -27 14 -42 15 -29 11 -72 -7 -61 -6 3 -7 1 -3 -5
27 -44 40 30 15 83 -10 22 -18 33 -19 25z"/>
<path d="M7865 9111 c-4 -7 1 -21 11 -33 10 -11 24 -32 31 -46 l11 -27 1 28
c1 15 -4 27 -9 27 -6 0 -17 14 -24 31 -9 20 -16 27 -21 20z"/>
<path d="M7900 9100 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M7931 9017 c-1 -16 6 -43 14 -60 8 -18 23 -54 33 -81 l19 -50 -1 62
c-1 33 -5 64 -9 68 -4 4 -4 -8 -1 -27 10 -52 -6 -28 -32 48 -16 50 -23 61 -23
40z"/>
<path d="M7960 9028 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10 -2z"/>
<path d="M8600 9013 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M7991 8994 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8600 8928 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10 -2z"/>
<path d="M8645 8687 c-1 -98 2 -144 6 -117 9 57 10 240 1 262 -4 10 -7 -56 -7
-145z"/>
<path d="M8027 8793 c-4 -3 -6 -22 -6 -42 1 -27 3 -30 6 -11 3 14 9 29 14 35
18 18 4 36 -14 18z"/>
<path d="M8047 8730 c-3 -11 -1 -20 4 -20 5 0 9 9 9 20 0 11 -2 20 -4 20 -2 0
-6 -9 -9 -20z"/>
<path d="M8032 8670 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M8062 8660 c0 -8 4 -26 8 -40 6 -20 8 -21 8 -5 0 11 -3 29 -8 40 -5
12 -8 14 -8 5z"/>
<path d="M8042 8615 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M8047 8395 c-5 -104 -9 -201 -9 -215 0 -14 4 -45 10 -70 l10 -45 1
40 c1 22 3 49 5 60 2 11 1 14 -1 8 -9 -26 -25 -13 -18 15 8 32 18 298 13 357
-2 22 -7 -45 -11 -150z"/>
<path d="M8074 8450 c0 -63 1 -89 3 -57 2 31 2 83 0 115 -2 31 -3 5 -3 -58z"/>
<path d="M8605 8394 c-1 -75 7 -75 15 1 4 34 2 56 -5 58 -6 3 -10 -19 -10 -59z"/>
<path d="M8570 8406 c0 -45 22 -119 31 -105 4 8 4 16 0 19 -5 3 -11 23 -15 45
-5 29 -4 36 3 25 6 -8 11 -10 11 -5 -1 6 -7 19 -15 29 -14 19 -15 19 -15 -8z"/>
<path d="M8071 8254 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8563 8212 c-1 -32 0 -33 7 -12 11 33 12 50 3 50 -5 0 -9 -17 -10
-38z"/>
<path d="M8540 8152 c0 -6 -9 -20 -21 -30 -16 -16 -17 -20 -5 -25 10 -4 18 4
25 25 6 17 9 34 6 36 -3 3 -5 0 -5 -6z"/>
<path d="M8022 8099 c-1 -15 -5 -42 -9 -60 -6 -30 -5 -33 9 -21 14 11 17 10
21 -5 3 -11 5 -8 6 10 0 16 -3 26 -8 22 -6 -3 -12 14 -14 37 -2 24 -4 31 -5
17z"/>
<path d="M8497 8050 c-10 -16 -14 -30 -8 -30 5 0 16 14 25 30 8 17 11 30 7 30
-4 0 -15 -13 -24 -30z"/>
<path d="M8071 8014 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8426 7981 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M7992 7960 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M8451 7948 c-5 -14 -18 -28 -30 -31 -16 -4 -19 -12 -18 -50 l2 -44
21 37 c25 44 46 101 39 108 -2 3 -9 -6 -14 -20z m-21 -52 c0 -8 -4 -18 -10
-21 -5 -3 -10 3 -10 14 0 12 5 21 10 21 6 0 10 -6 10 -14z"/>
<path d="M7971 7881 c-11 -45 -11 -55 0 -62 9 -5 16 -3 21 9 10 25 9 39 -2 22
-14 -22 -23 -5 -10 20 6 11 10 31 8 43 -2 15 -8 4 -17 -32z"/>
<path d="M8325 7830 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M8344 7785 c-8 -27 -8 -35 2 -39 14 -5 19 1 41 42 10 18 10 23 1 20
-7 -3 -16 -15 -20 -29 -6 -19 -7 -17 -3 9 6 42 -7 41 -21 -3z"/>
<path d="M8321 7794 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7946 7763 c2 -12 -4 -29 -13 -39 -13 -13 -14 -16 -3 -12 8 2 19 14
25 26 5 12 13 22 17 22 5 0 8 8 8 18 -1 16 -2 16 -14 -1 -11 -14 -15 -15 -19
-5 -2 7 -2 3 -1 -9z"/>
<path d="M7991 7774 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7991 7724 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8332 7718 c-7 -7 -12 -17 -12 -24 0 -7 -6 -15 -12 -17 -9 -4 -8 -6
2 -6 8 -1 21 11 27 26 14 30 12 38 -5 21z"/>
<path d="M7911 7687 c0 -10 -10 -36 -21 -59 -17 -36 -18 -43 -5 -67 10 -19 14
-21 15 -8 0 9 3 17 8 17 4 0 8 12 9 27 1 27 1 27 -11 5 -21 -39 -24 -14 -4 28
11 21 18 47 15 57 -4 17 -5 17 -6 0z"/>
<path d="M7960 7690 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8244 7644 c0 -29 1 -31 12 -16 17 22 17 39 2 45 -9 3 -13 -7 -14
-29z"/>
<path d="M8286 7644 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z"/>
<path d="M8161 7584 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8247 7573 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M7860 7546 c0 -8 4 -17 9 -20 5 -4 7 3 4 14 -6 23 -13 26 -13 6z"/>
<path d="M8201 7533 c3 -22 -10 -53 -22 -53 -12 0 -11 27 1 33 6 3 5 4 -2 3
-8 -2 -12 -15 -11 -31 1 -16 -3 -35 -9 -42 -6 -7 -6 -13 -1 -13 13 0 63 81 63
102 0 10 -5 18 -11 18 -5 0 -9 -8 -8 -17z"/>
<path d="M7827 7469 c-3 -12 -2 -31 2 -42 6 -17 8 -12 8 21 0 47 -2 52 -10 21z"/>
<path d="M8095 7420 c-10 -11 -15 -23 -12 -27 4 -4 14 2 21 13 8 10 18 16 22
11 4 -4 2 -13 -4 -19 -17 -17 -15 -33 3 -18 19 15 19 34 2 49 -11 8 -18 6 -32
-9z"/>
<path d="M7799 7403 c-7 -16 -15 -31 -17 -35 -2 -5 0 -8 5 -8 9 0 37 59 31 66
-3 2 -11 -8 -19 -23z"/>
<path d="M8086 7355 c4 -10 -2 -13 -24 -10 -40 5 -54 -13 -59 -77 l-4 -51 25
29 c13 16 22 31 20 34 -3 2 -9 -2 -14 -10 -14 -22 -20 -7 -11 26 6 24 13 29
38 29 35 0 63 29 38 39 -9 3 -12 0 -9 -9z"/>
<path d="M7768 7333 c-21 -24 -48 -79 -47 -95 0 -7 9 6 19 30 11 23 24 42 29
42 6 0 15 9 21 20 13 25 -1 26 -22 3z"/>
<path d="M7810 7301 c-7 -15 -7 -21 0 -21 12 0 25 28 17 36 -3 3 -10 -4 -17
-15z"/>
<path d="M8050 7305 c-7 -9 -8 -15 -2 -15 5 0 12 7 16 15 3 8 4 15 2 15 -2 0
-9 -7 -16 -15z"/>
<path d="M7746 7215 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
<path d="M7924 7185 c-3 -8 -3 -19 1 -24 3 -6 1 -21 -5 -33 -10 -21 -10 -21 9
2 22 27 28 45 11 35 -5 -3 -10 0 -10 9 0 8 6 17 13 19 9 4 9 6 -1 6 -7 1 -15
-6 -18 -14z"/>
<path d="M7965 7189 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M7664 7142 c-8 -13 -8 -20 1 -29 9 -8 12 -4 12 18 0 16 -1 29 -1 29
-1 0 -6 -8 -12 -18z"/>
<path d="M7876 7106 c3 -9 11 -16 16 -16 13 0 5 23 -10 28 -7 2 -10 -2 -6 -12z"/>
<path d="M7643 7099 c-22 -28 -34 -59 -23 -59 6 0 14 8 17 18 4 9 11 20 16 23
5 4 8 12 5 19 -3 9 -6 9 -15 -1z"/>
<path d="M7840 7095 c0 -8 5 -15 11 -15 6 0 8 -7 5 -16 -6 -15 -4 -15 10 -3
10 8 12 15 5 17 -6 2 -11 10 -11 18 0 8 -4 14 -10 14 -5 0 -10 -7 -10 -15z"/>
<path d="M7821 7031 c-8 -5 -21 -7 -29 -4 -14 6 -32 -12 -32 -32 1 -5 5 -2 11
8 12 21 29 22 29 2 0 -8 -5 -15 -12 -15 -6 0 -8 -3 -4 -7 3 -4 20 8 36 25 28
31 29 40 1 23z"/>
<path d="M7578 6992 c-16 -26 -9 -67 8 -50 6 7 16 78 10 78 -1 0 -9 -13 -18
-28z"/>
<path d="M7731 6928 c-18 -21 -27 -36 -19 -33 15 6 63 64 56 69 -1 1 -18 -15
-37 -36z"/>
<path d="M7704 6945 c-10 -8 -14 -14 -9 -15 15 0 40 18 34 24 -3 3 -14 -1 -25
-9z"/>
<path d="M7540 6921 c-7 -15 -7 -21 0 -21 12 0 25 28 17 36 -3 3 -10 -4 -17
-15z"/>
<path d="M7576 6893 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M7510 6879 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7620 6855 c-13 -14 -20 -25 -16 -25 11 0 50 39 44 45 -2 3 -15 -6
-28 -20z"/>
<path d="M7674 6866 c-6 -6 -39 -37 -73 -70 -34 -32 -59 -61 -56 -64 3 -3 37
27 76 67 40 39 70 73 68 75 -2 2 -9 -1 -15 -8z"/>
<path d="M7478 6835 c-23 -31 -23 -31 9 -30 19 0 28 6 29 18 1 12 1 13 -3 2
-2 -8 -9 -15 -14 -15 -13 0 -11 24 3 38 7 7 8 12 3 12 -6 0 -18 -11 -27 -25z"/>
<path d="M7545 6787 c-25 -29 -26 -40 -2 -18 15 14 27 26 27 28 0 7 -16 1 -25
-10z"/>
<path d="M7406 6734 c-3 -9 -2 -12 5 -9 5 4 17 1 26 -7 15 -12 15 -11 2 10
-16 26 -25 28 -33 6z"/>
<path d="M7456 6695 c-9 -14 -13 -25 -9 -25 12 1 46 50 35 50 -6 0 -17 -11
-26 -25z"/>
<path d="M7494 6688 c-29 -31 -29 -31 -2 -12 15 10 29 25 32 32 8 18 2 15 -30
-20z"/>
<path d="M7381 6696 c-8 -9 -11 -19 -7 -23 9 -9 29 13 24 27 -2 8 -8 7 -17 -4z"/>
<path d="M7400 6661 c0 -6 9 -9 19 -8 11 0 17 5 15 9 -7 11 -34 10 -34 -1z"/>
<path d="M7303 6595 c-35 -37 -39 -45 -22 -45 5 0 7 5 4 10 -3 6 1 10 9 10 19
0 21 -11 4 -28 -7 -7 -8 -12 -4 -12 5 0 17 10 27 21 10 12 30 23 45 25 23 2
53 28 54 47 0 16 -18 6 -23 -13 -3 -11 -11 -20 -17 -20 -7 0 -10 8 -7 20 3 11
1 20 -4 20 -5 0 -9 -6 -9 -13 0 -17 -38 -52 -47 -44 -3 4 4 18 17 32 13 14 19
25 14 25 -5 0 -24 -16 -41 -35z"/>
<path d="M7232 6508 c-12 -18 -19 -35 -16 -38 3 -3 10 3 15 12 5 9 16 24 24
32 17 17 19 26 6 26 -5 0 -18 -15 -29 -32z"/>
<path d="M7260 6495 c-9 -11 -8 -14 9 -12 22 1 28 12 12 21 -5 3 -14 -1 -21
-9z"/>
<path d="M7172 6439 c-13 -15 -22 -31 -22 -35 1 -4 -5 -11 -14 -16 -9 -5 -14
-13 -11 -19 4 -5 0 -9 -7 -9 -7 0 -31 -19 -53 -42 -55 -56 -14 -32 60 36 33
31 51 53 40 50 -20 -5 -20 -5 -1 10 10 8 23 13 27 10 10 -6 71 37 64 44 -2 3
-11 -1 -20 -8 -10 -8 -19 -9 -27 -3 -9 8 -19 2 -36 -18z"/>
</g>
</svg>

    </Box> 
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
